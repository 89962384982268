import { graphql } from "gatsby";
import * as NFPData from "../../../pageData/NFPData/NFPData.json"
const query = graphql`
  query NFPQuery {
    allPrismicNFP {
      nodes {
        data {
          nfp_top_heading {
            text
          }
          nfp_top_description{
            text
          }
          nfp_right_image{
            url
          }
          nfp_top_heading1 {
            text
          }
          nfp_top_description1{
            text
          }
          nfp_left_image{
            url
          }
          nfp_top_description2{
            text
          }
          nfp_heading {
            text
          }
          terms_box{
            DemoTerms_content {
              url
            }
          }
          dubaiimages_box { 
            dubaiimages_icon {
              url
            }
          }
          four_box {            
            four_icon {
              url
            }
            four_heading {
              text
            }
            four_content {
              text
            }
          },
          three_box { 
            three_heading {
              text
            }
            three_content {
              text
            }
          },
          key_figures_boxes {
            key_feature_box_description {
              text
            }
            key_feature_box_title {
              text
            }
            number_only {
              text
            }
            number_prefix {
              text
            }
            number_sufix {
              text
            }
          }
          questionsbox {
            question {
              text
            }
            answer {
              text
              html
            }
          }
          demo_side_image {
            url
          }
          demo_heading {
            text
          }
          demo_content {
            text
          }
          demo_link {
            text
          }
          demo_link_text {
            text
          }
        }
        lang
      }
    }
  }
`;

const dataResolver = (response, lang) => {
  const { allPrismicNFP } = response;
  return allPrismicNFP.nodes.find((node) => node.lang === lang).data;
};

const useData = (lang) => {
  // debugger;
  // const response = useStaticQuery(query);
  // return dataResolver(response, lang);
  return dataResolver(NFPData, lang);
};

export default useData;
